import type { ConnectResult, Provider } from '@wagmi/core';
import React, { ReactElement } from 'react';
import { Chain, useAccount, useConnect, useDisconnect, useNetwork, useProvider, useSwitchNetwork } from 'wagmi';
import { Web3Context } from '../useWeb3Context';

export type Web3Data = {
  connectWallet: () => Promise<ConnectResult<Provider>>;
  disconnectWallet: () => void;
  currentAccount: string;
  connected: boolean;
  loading: boolean;
  provider: Provider;
  chainId: number;
  network: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Error | null;
  switchNetworkAsync: ((chainId_?: number | undefined) => Promise<Chain>) | undefined;
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { connectAsync, connectors, error } = useConnect();

  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const { isConnected, isConnecting, address: account } = useAccount();
  const provider = useProvider();
  const { switchNetworkAsync } = useSwitchNetwork();

  return (
    <Web3Context.Provider
      value={{
        web3ProviderData: {
          connectWallet: () => connectAsync({ connector: connectors[0] }),
          disconnectWallet: disconnect,
          provider,
          connected: isConnected,
          loading: isConnecting,
          chainId: chain?.id || 2001,
          network: chain?.id == 2001 ? 'mainnet' : 'testnet',
          currentAccount: account?.toLowerCase() || '',
          error,
          switchNetworkAsync
        }
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
