exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-connect-wallet-tsx": () => import("./../../../src/pages/connect-wallet.tsx" /* webpackChunkName: "component---src-pages-connect-wallet-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-corporate-tsx": () => import("./../../../src/pages/corporate.tsx" /* webpackChunkName: "component---src-pages-corporate-tsx" */),
  "component---src-pages-engine-license-tsx": () => import("./../../../src/pages/engine-license.tsx" /* webpackChunkName: "component---src-pages-engine-license-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-junglewars-index-tsx": () => import("./../../../src/pages/junglewars/index.tsx" /* webpackChunkName: "component---src-pages-junglewars-index-tsx" */),
  "component---src-pages-junglewars-leaderboard-tsx": () => import("./../../../src/pages/junglewars/leaderboard.tsx" /* webpackChunkName: "component---src-pages-junglewars-leaderboard-tsx" */),
  "component---src-pages-kyc-tsx": () => import("./../../../src/pages/kyc.tsx" /* webpackChunkName: "component---src-pages-kyc-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("./../../../src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */),
  "component---src-pages-my-nfts-tsx": () => import("./../../../src/pages/my-nfts.tsx" /* webpackChunkName: "component---src-pages-my-nfts-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-nft-collection-tsx": () => import("./../../../src/pages/nft-collection.tsx" /* webpackChunkName: "component---src-pages-nft-collection-tsx" */),
  "component---src-pages-nft-manifesto-tsx": () => import("./../../../src/pages/nft-manifesto.tsx" /* webpackChunkName: "component---src-pages-nft-manifesto-tsx" */),
  "component---src-pages-paima-engine-tsx": () => import("./../../../src/pages/paima-engine.tsx" /* webpackChunkName: "component---src-pages-paima-engine-tsx" */),
  "component---src-pages-pending-buy-nft-tsx": () => import("./../../../src/pages/pending-buy-nft.tsx" /* webpackChunkName: "component---src-pages-pending-buy-nft-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-pro-tsx": () => import("./../../../src/pages/pro.tsx" /* webpackChunkName: "component---src-pages-pro-tsx" */),
  "component---src-pages-tarochi-index-tsx": () => import("./../../../src/pages/tarochi/index.tsx" /* webpackChunkName: "component---src-pages-tarochi-index-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-tower-defense-index-tsx": () => import("./../../../src/pages/tower-defense/index.tsx" /* webpackChunkName: "component---src-pages-tower-defense-index-tsx" */),
  "component---src-pages-tower-defense-leaderboard-tsx": () => import("./../../../src/pages/tower-defense/leaderboard.tsx" /* webpackChunkName: "component---src-pages-tower-defense-leaderboard-tsx" */),
  "component---src-pages-volcaneers-tsx": () => import("./../../../src/pages/volcaneers.tsx" /* webpackChunkName: "component---src-pages-volcaneers-tsx" */)
}

