// import * as dotenv from "dotenv";
// dotenv.config({ path: `.env.${process.env.NODE_ENV}` });

export const RPC_URL: string = process.env.GATSBY_RPC_URL === undefined ? '' : process.env.GATSBY_RPC_URL;

export const ARBITRUM_RPC_URL: string =
  process.env.GATSBY_ARBITRUM_RPC_URL === undefined ? '' : process.env.GATSBY_ARBITRUM_RPC_URL;

export const XAI_RPC_URL: string = process.env.GATSBY_XAI_RPC_URL === undefined ? '' : process.env.GATSBY_XAI_RPC_URL;

export const POSTHOG_KEY: string = process.env.GATSBY_POSTHOG_KEY === undefined ? '' : process.env.GATSBY_POSTHOG_KEY;

export const GA_ID: string = process.env.GATSBY_GA_ID === undefined ? '' : process.env.GATSBY_GA_ID;

export const GTM_ID: string = process.env.GATSBY_GTM_ID === undefined ? '' : process.env.GATSBY_GTM_ID;

export const GTM_ENV: string | null = process.env.GATSBY_GTM_ENV === undefined ? null : process.env.GATSBY_GTM_ENV;

export const NFT_SALE_PROXY: string =
  process.env.GATSBY_NFT_SALE_PROXY === undefined ? '' : process.env.GATSBY_NFT_SALE_PROXY;

export const NATIVE_NFT_SALE_PROXY: string =
  process.env.GATSBY_NATIVE_NFT_SALE_PROXY === undefined ? '' : process.env.GATSBY_NATIVE_NFT_SALE_PROXY;

export const TAROCHI_SALE_PROXY: string =
  process.env.GATSBY_TAROCHI_SALE_PROXY === undefined ? '' : process.env.GATSBY_TAROCHI_SALE_PROXY;

export const TAROCHI_SALE_MINT_LIVE: number =
  process.env.GATSBY_TAROCHI_SALE_MINT_LIVE === undefined ? 0 : Number(process.env.GATSBY_TAROCHI_SALE_MINT_LIVE);

export const TAROCHI_SALE_WHITELIST_END: number =
  process.env.GATSBY_TAROCHI_SALE_WHITELIST_END === undefined
    ? 0
    : Number(process.env.GATSBY_TAROCHI_SALE_WHITELIST_END);

export const TAROCHI_SALE_MINT_END: number =
  process.env.GATSBY_TAROCHI_SALE_MINT_END === undefined ? 0 : Number(process.env.GATSBY_TAROCHI_SALE_MINT_END);

export const NFT: string = process.env.GATSBY_NFT === undefined ? '' : process.env.GATSBY_NFT;

export const EXPLORER_URL: string =
  process.env.GATSBY_EXPLORER_URL === undefined ? '' : process.env.GATSBY_EXPLORER_URL;

export const CHAIN_ID: string = process.env.GATSBY_CHAIN_ID === undefined ? '' : process.env.GATSBY_CHAIN_ID;

export const NETWORK_NAME: string =
  process.env.GATSBY_NETWORK_NAME === undefined ? '' : process.env.GATSBY_NETWORK_NAME;

export const ALLOWANCE_VALUE: string =
  process.env.GATSBY_ALLOWANCE_VALUE === undefined ? '' : process.env.GATSBY_ALLOWANCE_VALUE;

export const INDEXER_BASE_URL: string =
  process.env.GATSBY_INDEXER_BASE_URL === undefined ? '' : process.env.GATSBY_INDEXER_BASE_URL;

export const RANKINGS_URL_JW: string =
  process.env.GATSBY_RANKINGS_URL_JW === undefined ? '' : process.env.GATSBY_RANKINGS_URL_JW;

export const RANKINGS_URL_TD: string =
  process.env.GATSBY_RANKINGS_URL_TD === undefined ? '' : process.env.GATSBY_RANKINGS_URL_TD;

export const APP_CONNECTED = 'APP_CONNECTED';

export const ACCOUNT = 'ACCOUNT';

export const CHAIN = 'CHAIN';

export const NATIVE_SALE = 'NATIVE';

export const ERC20_SALE = 'ERC20';

export const MY_NFT_BG = 'MY_NFT';

export const COLLECTION_BG = 'COLLECTION';

export const HOME_BG = 'HOME';

export const CAROUSEL_BG = 'CAROUSEL';

export const HEADER_BG = 'HEADER';

export const VOLCANEERS_BG = 'VOLCANEERS';

export enum MintStatus {
  MINTED = 'MINTED',
  UNMINTED = 'UNMINTED'
}

export const LOCAL_STORAGE_PREFERRED_SITE_LANGUAGE = 'paima-preferred-site-language';

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

// recall: 999 are left for the treasury
export const TAROCHI_GENESIS_NFT_MAX_SUPPLY = 9001;
