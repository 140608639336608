import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { Chain, configureChains, createClient, createStorage } from 'wagmi';
import { arbitrum } from 'wagmi/chains';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { ARBITRUM_RPC_URL, RPC_URL, XAI_RPC_URL } from '../../services/constants';

export const supportedChains: Chain[] = [
  // Milkomeda
  // {
  //   id: Number(CHAIN_ID),
  //   name: NETWORK_NAME,
  //   network: NETWORK_NAME,
  //   nativeCurrency: {
  //     name: 'Milk Ada',
  //     symbol: 'mADA',
  //     decimals: 18
  //   },
  //   rpcUrls: {
  //     public: { http: [RPC_URL] },
  //     default: { http: [RPC_URL] }
  //   },
  //   blockExplorers: {
  //     etherscan: { name: '', url: '' },
  //     default: {
  //       name: '',
  //       url: EXPLORER_URL
  //     }
  //   }
  // }
  arbitrum,
  {
    id: 421614,
    name: 'Arbitrum Sepolia',
    network: 'arbitrum-sepolia',
    nativeCurrency: {
      name: 'Arbitrum Sepolia Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: {
      public: { http: [ARBITRUM_RPC_URL] },
      default: { http: [ARBITRUM_RPC_URL] }
    },
    blockExplorers: {
      etherscan: {
        name: 'Arbiscan',
        url: 'https://sepolia.arbiscan.io/'
      },
      default: {
        name: 'Arbiscan',
        url: 'https://sepolia.arbiscan.io/'
      }
    }
  },
  // xai
  {
    id: 660279,
    name: 'Xai',
    network: 'xai',
    nativeCurrency: {
      name: 'Xai',
      symbol: 'XAI',
      decimals: 18
    },
    rpcUrls: {
      public: { http: [XAI_RPC_URL] },
      default: { http: [XAI_RPC_URL] }
    },
    blockExplorers: {
      etherscan: { name: '', url: '' },
      default: {
        name: 'Blockscout',
        url: 'https://explorer.xai-chain.net/'
      }
    }
  },
  {
    id: 47279324479,
    name: 'Xai testnet',
    network: 'xai-testnet',
    nativeCurrency: {
      name: 'Xai',
      symbol: 'XAI',
      decimals: 18
    },
    rpcUrls: {
      public: { http: [XAI_RPC_URL] },
      default: { http: [XAI_RPC_URL] }
    },
    blockExplorers: {
      etherscan: { name: '', url: '' },
      default: {
        name: 'Blockscout',
        url: 'https://testnet-explorer.xai-chain.net/'
      }
    }
  }
];

export const { provider, webSocketProvider } = configureChains(supportedChains, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (chain.id === 660279 || chain.id === 47279324479) {
        return { http: XAI_RPC_URL };
      } else if (chain.id === 42161 || chain.id === 421614) {
        return { http: ARBITRUM_RPC_URL };
      }
      return {
        http: RPC_URL
      };
    }
  })
]);

export const client = createClient({
  autoConnect: true,
  ...(typeof window !== 'undefined' && {
    storage: createStorage({
      storage: window.localStorage,
      key: 'ps.volcanners'
    })
  }),
  // moving connectors here to persist account on local storage
  connectors: [
    new MetaMaskConnector({
      chains: supportedChains
    })
  ],
  provider,
  webSocketProvider
});
