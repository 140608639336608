import * as React from 'react';
import { WagmiConfig } from 'wagmi';
import { client } from './src/hooks/web3-data-provider/wagmi';
import { Web3ContextProvider } from './src/hooks/web3-data-provider/Web3Provider';
import { LOCAL_STORAGE_PREFERRED_SITE_LANGUAGE } from './src/services/constants';
import './src/styles/global.css';

// Wraps app root
export const wrapRootElement = ({ element }) => {
  return (
    <WagmiConfig client={client}>
      <Web3ContextProvider>{element}</Web3ContextProvider>
    </WagmiConfig>
  );
};

export const onClientEntry = () => {
  const japaneseLangCodes = ['ja', 'jp', 'jpn'];

  if (
    japaneseLangCodes.includes(navigator.language) &&
    window.location.pathname.indexOf('jp') === -1 &&
    localStorage.getItem(LOCAL_STORAGE_PREFERRED_SITE_LANGUAGE) !== 'en'
  ) {
    window.location.pathname = `/jp${window.location.pathname}`;
  }
};
